.login {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
  max-width: $card-width * 2;
  max-height: $card-height * 2;
  padding: $gutter-large $gutter;
  text-align: center;
  background-color: $color-white;
  transform: translate(-50%, -50%);

  &::before {
    @extend %box-shadow;
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    content: "";
  }
}

.login__heading {
  position: relative;
  width: 100%;
  margin: 0 0 $gutter-small;
}

.login__link {
  position: relative;
  display: block;
  width: 191px;
  height: 46px;
  padding: 0;
  margin: 0 auto;
  background-image: url("../../img/btn_google_signin_dark_normal_web.png");
  background-size: cover;
  border: 0;

  &:disabled {
    background-image: url("../../img/btn_google_signin_dark_disabled_web.png");
  }

  &:focus,
  &:hover {
    background-image: url("../../img/btn_google_signin_dark_focus_web.png");
  }

  &:active {
    background-image: url("../../img/btn_google_signin_dark_pressed_web.png");
  }

  @media (min-resolution: 192dpi) {
    background-image: url("../../img/btn_google_signin_dark_normal_web@2x.png");

    &:disabled {
      background-image: url("../../img/btn_google_signin_dark_disabled_web@2x.png");
    }

    &:focus,
    &:hover {
      background-image: url("../../img/btn_google_signin_dark_focus_web@2x.png");
    }

    &:active {
      background-image: url("../../img/btn_google_signin_dark_pressed_web@2x.png");
    }
  }
}

.login__screenreader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}
