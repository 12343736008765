.store {
  z-index: 0;
  width: 100%;
}

.store__description,
.store__description--heading {
  margin-top: 0;
}


.store__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  text-align: left;
}

.store__form-input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.store__form-label {
  position: absolute;
  font-size: 0.9375rem;
  top: 1.0625rem;
  left: 0.625rem;
  line-height: 1;
  transition: 125ms;
  transition-delay: 125ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.store__form-input {
  padding: 1.5rem 0.625rem 0.375rem;
  margin: 0;
  font-size: 0.75rem;
  border: 1px solid #dddddd;
  transition: all 125ms cubic-bezier(0.4, 0, 0.2, 1);

  &:focus {
    border-color: #818181;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 0 0 0.5px #919191;
    outline: 0;

    + .store__form-label {
      top: 0.5rem;
      font-size: 0.8125rem;
    }
  }
}

.store__form-submit {
  line-height: 1.15;
  font-size: 0.8rem;
  margin: 0;
  width: 7rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
  background-color: $color-background-tertiary;
  border: 1px solid $color-background-tertiary;
  border-radius: 3px;
  color: $color-white;
}

.store__form-input-wrapper--filled {
  .store__form-label {
    top: 0.5rem;
    font-size: 0.8125rem;
  }
}
