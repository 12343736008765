h1,
h2,
h3,
h4 {
  @extend %letter-spacing;
  font-family: $font-primary;
  font-weight: 300;
  color: $color-font;
}

h2 {
  font-size: $font-header;
}

h3 {
  font-size: $font-large;
}

h4 {
  font-size: $font-small;
  font-weight: 100;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  outline: 0;
}
