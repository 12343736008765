.themes-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: $gutter;
  margin: 0 auto;

  @include media($min: $breakpoint-tablet) {
    padding: $gutter $gutter-medium;
  }

  @include media($min: $breakpoint-desktop) {
    padding: $gutter $gutter-large;
  }
}

.themes-dashboard__content {
  position: relative;
}
