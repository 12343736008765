%box-shadow {
  box-shadow: 0 0 20px 0 rgba($color-background-primary, 0.4);
}

%letter-spacing {
  letter-spacing: 2px;
}

%text-shadow {
  text-shadow: -2px 2px 4px rgba($color-background-primary, 0.3);
}

%gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.85;
  transition: opacity $transition;

  .placeholder & {
    opacity: 1;
  }

  .nav--link-active {
    .icon--latest,
    .icon--insights,
    .icon--releases {
      color: darken(#fff, 10%);
    }
  }

  .table--heading-active {
    .icon--up,
    .icon--down {
      color: darken(#fff, 10%);
    }
  }

  *::selection {
    background: rgba(#fff, 0.3);
  }

  &::before {
    background-image: linear-gradient(to right bottom, #433752, darken(#433752, 10%));
  }
}

%placeholder {
  position: relative;
  overflow: hidden;
  transition: opacity $transition $transition-delay;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    content: "";
    transition: background-color $transition;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 30px;
    pointer-events: none;
    content: "";
    transition: background-image $transition;
  }
}

%placeholder-text {
  @extend %placeholder;
  opacity: 1;

  .placeholder & {
    opacity: 0.3;
  }

  .placeholder &::after {
    pointer-events: all;
    background-color: lighten($color-background-primary, 70%);
  }

  .placeholder &::before {
    pointer-events: all;
    background-image: linear-gradient(to left, rgba($color-white, 0), rgba($color-white, 0.5), rgba($color-white, 0));
    animation: placeholder 1.5s infinite;
  }
}
