@mixin media($max: false, $min: false) {
  @if $max {
    @if $min {
      @media screen and (max-width: $max - 1) and (min-width: $min) { @content; }
    } @else {
      @media screen and (max-width: $max - 1) { @content; }
    }
  } @else {
    @media screen and (min-width: $min) { @content; }
  }
}

@mixin placeholder {
  @extend %placeholder;
  opacity: 0.3;

  &::after {
    pointer-events: all;
    background-color: lighten($color-background-primary, 70%);
  }

  &::before {
    pointer-events: all;
    background-image: linear-gradient(to left, rgba($color-white, 0), rgba($color-white, 0.5), rgba($color-white, 0));
    animation: placeholder 1.5s infinite;
  }
}
