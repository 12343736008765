.navigation-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: lighten($color-background-primary, 80%);

  @include media($min: $breakpoint-tablet) {
    position: static;
    display: block;
    width: auto;
    height: 100%;
  }
}

.navigation {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media($min: $breakpoint-tablet) {
    display: block;
  }
}

.navigation__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation__menu--depth-2 {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 15px;
    width: 0;
    height: 0;
    border-top: 10px solid lighten($color-background-primary, 80%);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    content: "";
  }
}

.navigation__item--depth-1 {
  background-color: lighten($color-background-primary, 80%);

  &:hover {
    background-color: lighten($color-background-primary, 70%);

    .navigation__menu--depth-2::after {
      border-top-color: lighten($color-background-primary, 70%);
    }
  }
}

.navigation__item--depth-2 {
  background-color: lighten($color-background-primary, 75%);

  &:hover {
    background-color: lighten($color-background-primary, 65%);
  }
}

.navigation__item {
  &:not(:last-child) {
    border-bottom: 1px solid lighten($color-background-primary, 70%);
  }
}

.navigation__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  padding: $gutter-small $gutter;
  margin: 0;
  font-size: $font-medium;
  line-height: 1;
  color: $color-font;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity $transition;

  @include media($min: $breakpoint-tablet) {
    justify-content: initial;
  }

  &:hover,
  &:focus,
  &.navigation__link--active {
    opacity: 1;
  }
}

.navigation__link--depth-2 {
  font-size: $font-small;
}

.navigation__link-icon {
  width: 18px;
  height: 18px;
  margin-right: 0;
  transition: color $transition;

  @include media($min: $breakpoint-desktop) {
    margin-right: $gutter-small;
  }
}

.navigation__link-label {
  display: none;

  @include media($min: $breakpoint-desktop) {
    display: block;
  }
}
