html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-secondary;
  font-size: $font-body;
  font-weight: 300;
  line-height: 1.5;
  color: $color-font;
  background-image: radial-gradient(circle at 0% 0%, $color-background-tertiary, $color-background-secondary 51%, $color-background-primary);
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

[data-tooltip] {
  &::after {
    position: absolute;
    bottom: 36px;
    left: 50%;
    min-width: 100px;
    padding: $gutter-smaller $gutter-small;
    font-size: $font-small;
    color: $color-white;
    text-align: center;
    pointer-events: none;
    background-color: rgba($color-background-primary, 0.7);
    content: attr(data-tooltip);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) translateY(5px);
    transition: opacity $transition, transform $transition;
  }

  &::before {
    position: absolute;
    bottom: 30px;
    left: 50%;
    pointer-events: none;
    border: solid;
    border-color: rgba($color-background-primary, 0.7) transparent;
    border-width: 6px 6px 0;
    content: "";
    opacity: 0;
    transform: translateX(-50%) translateY(5px);
    transition: opacity $transition, transform $transition;
  }

  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
  }
}
