// Breakpoints
$max-width: 1150px;

$breakpoint-desktop: 1275px;
$breakpoint-tablet: 720px;

// Colors
$color-background-primary: #1d1e26;
$color-background-secondary: #252736;
$color-background-tertiary: #373b52;

$color-font: $color-background-primary;
$color-accent: #9ba2ff;
$color-white: lighten($color-accent, 18.9%);

$color-red: #ff6b6b;
$color-orange: #fcaa67;
$color-yellow: #ffdd4a;
$color-blue: #5adbff;
$color-green: #56e39f;
$color-pink: #f7b2b7;

// Font families
$font-primary: 'Roboto Condensed', sans-serif;
$font-secondary: 'Roboto', sans-serif;

// Font sizes
$font-small: 12px;
$font-medium: 14px;
$font-body: 18px;
$font-large: 30px;
$font-header: 42px;

// Gutters
$gutter-smallest: 5px;
$gutter-smaller: 10px;
$gutter-small: 15px;
$gutter: 30px;
$gutter-medium: 45px;
$gutter-large: 60px;

// Cards
$card-width: 340px;
$card-height: $card-width / 2;

// Transition
$transition: 0.2s;
$transition-delay: $transition;
