.tab {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  // Header(85px) + Hero(150px) + Nav(80px) + Footer(60px) = 375px
  min-height: calc(100vh - 375px);
  padding: 0 0 $gutter;
  opacity: 0;
  transition: opacity 0;

  @include media($min: $breakpoint-tablet) {
    // Header(85px) + Hero(225px) + Footer(60px) = 370px
    min-height: calc(100vh - 370px);
  }

  @include media($min: $breakpoint-desktop) {
    // Header(85px) + Hero(300px) + Footer(60px) = 445px
    min-height: calc(100vh - 445px);
  }
}

.tab--insights {
  padding: 0 $gutter $gutter;
}

.tab--fade {
  opacity: 1;
  transition: opacity $transition;
}

.tab--content {
  position: relative;
  width: 100%;
}

.tab--header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gutter;
}

.tab--heading {
  margin: 0;
  font-family: $font-secondary;
  font-size: $font-body;
  line-height: 1;
  text-transform: capitalize;
}

.tab--search {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.tab--search-field {
  padding: $gutter-smallest 0;
  margin-right: $gutter;
  background-color: $color-white;
  border: 0;
  border-bottom: 1px solid lighten($color-background-tertiary, 65%);
  outline: 0;
  transform: scaleX(0);
  transition: transform $transition $transition-delay;
  transform-origin: right;

  &::placeholder {
    opacity: 0;
    transition: opacity $transition;
  }

  &:focus,
  &:valid {
    z-index: 2;
    transform: scaleX(1);
    transition: transform $transition;

    &::placeholder {
      opacity: 1;
      transition: opacity $transition $transition-delay;
    }
  }
}

.tab--search-icon {
  position: absolute;
  top: 50%;
  right: 0;
  width: 22px;
  height: 22px;
  transform: translateY(-50%);
}

.column--published,
.column--tag {
  @include media($max: $breakpoint-tablet) {
    &:empty {
      display: none;
    }
  }
}

.column--released {
  text-align: center;
}

.column--tag,
.column--version,
.column--published,
.column--id,
.column--external_links {
  width: 100px;
}

.column--url {
  width: 130px;
}

.tab--limit {
  position: absolute;
  top: 0;
  right: $gutter-large;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
}

.tab--limit-select {
  display: block;
  padding: $gutter-smallest $gutter $gutter-smallest 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
}

.tab--limit-icon {
  position: absolute;
  top: 50%;
  right: #{$gutter - 5px};
  width: 10px;
  height: 100%;
  pointer-events: none;
  transform: translateY(-50%);
}

.chartjs-render-monitor {
  max-width: 100%;
}
