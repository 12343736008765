:root {
  --duet-color-primary: #005fcc;
  --duet-color-text: #333;
  --duet-color-text-active: #fff;
  --duet-color-button: #f5f5f5;
  --duet-color-surface: inherit;
  --duet-color-overlay: rgba(0, 0, 0, 0.8);

  --duet-font: 'Roboto Condensed', sans-serif;
  --duet-font-normal: 400;
  --duet-font-bold: 600;

  --duet-radius: 0;
  --duet-z-index: 600;
}

.duet-date__input {
  border: 0;
}

.duet-date__toggle {
  height: calc(100% - 1px);
}

.duet-date__dialog.is-active {
  background-color: $color-white;
}

.date-filter__wrapper {
  display: flex;
  justify-content: space-between;
  background-color: darken($local-color-table-cell-background, 10%);
}

.date-filter__item {
  max-width: 160px;
}
